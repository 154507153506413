import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-prestige-classes"></a><h2>Epic Prestige Classes
    </h2>
    <a id="legendary-dreadnought"></a><h3>LEGENDARY DREADNOUGHT</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d12.
    <a id="legendary-dreadnought-requirements"></a><h6>Requirements</h6>
To qualify to become a legendary dreadnought, a character must
fulfill all the following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Base Attack Bonus:</span>
+23. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="skillsAll.html#intimidate" style={{
        "color": "rgb(87, 158, 182)"
      }}>Intimidate</a>
15 ranks. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Feats:</span> <a href="featsAll.html#combat-reflexes" style={{
        "color": "rgb(87, 158, 182)"
      }}>Combat
Reflexes</a>, <a href="featsAll.html#great-cleave" style={{
        "color": "rgb(87, 158, 182)"
      }}>Great Cleave</a>, <a href="featsAll.html#improved-bull-rush" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved Bull Rush</a>, <a href="featsAll.html#improved-critical" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved Critical</a>. </p>
    <p style={{
      "fontWeight": "bold"
    }}>Class Skills</p>
    <p>The legendary dreadnought&#8217;s class skills (and the key ability
for each skill) are <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a> (Str), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#intimidate" style={{
        "color": "rgb(87, 158, 182)"
      }}>Intimidate</a> (Cha), <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
(Str), and <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a> (Str). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
2 + Int modifier. </p>
    <p>
    </p>
    <a id="table-the-legendary-dreadnought"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Legendary Dreadnought</span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>1st
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Unstoppable
1/day
          </td>
        </tr>
        <tr>
          <td>2nd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Unmovable
1/day</td>
        </tr>
        <tr className="odd-row">
          <td>3rd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Shrug off
punishment</td>
        </tr>
        <tr>
          <td>4th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Thick
skinned</td>
        </tr>
        <tr className="odd-row">
          <td>5th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
        <tr>
          <td>6th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Unstoppable
2/day</td>
        </tr>
        <tr className="odd-row">
          <td>7th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Unmovable
2/day</td>
        </tr>
        <tr>
          <td>8th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Shrug off
punishment</td>
        </tr>
        <tr className="odd-row">
          <td>9th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Thick
skinned</td>
        </tr>
        <tr>
          <td className="last-row">10th
          </td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
      </tbody>
    </table>
    <a id="legendary-dreadnought-class-features"></a><h6>Class Features</h6>
The following are class features of the legendary dreadnought prestige
class. 
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
A legendary dreadnought is proficient with all simple and martial
weapons, all armor, and all shields. </p>
    <a id="legendary-dreadnought-unstoppable"></a><p><span style={{
        "fontWeight": "bold"
      }}>Unstoppable (Ex):</span> At
1st level, the legendary dreadnought can concentrate his or her power,
gaining a +20 bonus on his or her Strength check to break or burst a
door or item once per day, plus one additional time per day every five
levels thereafter. As a special use of this ability, the legendary
dreadnought can attempt to break a wall of force (Strength DC 32, and
the character applies his or her unstoppable bonus to this check as
well). Alternatively, the legendary dreadnought can apply the +20 bonus
to a single attack roll. </p>
    <a id="legendary-dreadnought-unmovable"></a><p><span style={{
        "fontWeight": "bold"
      }}>Unmovable (Ex):</span> At 2nd
level, the legendary dreadnought can concentrate his or her power,
making him or herself unmovable once per day, plus one additional time
per day every five levels thereafter.&nbsp; This power grants the
character a +20 bonus on any one of the following: </p>
    <p>A grapple check made to avoid being grabbed with the improved
grab ability. </p>
    <p>A Strength check to avoid the effects of a bull rush, trip
attempt, or similar effect. </p>
    <p>A Strength check against any effect that would move the
character either physically or magically. </p>
    <p>Any one saving throw. If an effect that would move the
character either physically or magically does not normally allow a
saving throw, the legendary dreadnought can use this ability to gain a
Will saving throw. He or she still gains the +20 bonus on the saving
throw in such a case. &nbsp;</p>
    <a id="legendary-dreadnought-shrug-off-punishment"></a><p><span style={{
        "fontWeight": "bold"
      }}>Shrug off Punishment (Ex):</span>
The legendary dreadnought gains 12 bonus hit points at 3rd level and 12
more every five levels thereafter. </p>
    <a id="legendary-dreadnought-thick-skinned"></a><p><span style={{
        "fontWeight": "bold"
      }}>Thick Skinned (Ex):</span> At
4th level, the legendary dreadnought gains damage reduction 3/-. This
does not stack with damage reduction granted by magic items or
nonpermanent magical effects, but it does stack with any damage
reduction granted by permanent magical effects, class features, the
Damage Reduction feat, and this ability itself. The damage reduction
improves by 3 points every five levels thereafter</p>
    <a id="legendary-dreadnought-bonus-feats"></a><p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The
legendary dreadnought gets a bonus feat at 5th level and an additional
bonus feat every five levels thereafter. These bonus feats must be
selected from the following list: Armor Skin, Devastating Critical,
Dire Charge, Epic Fortitude, Epic Prowess, Epic Toughness, Epic Weapon
Focus, Epic Weapon Specialization, Fast Healing, Great Constitution,
Great Strength, Improved Combat Reflexes, Overwhelming Critical,
Penetrate Damage Reduction. </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      